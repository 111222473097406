
import { defineComponent, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "administrators-list-table-component",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      administrators: [
        {
          avatar: "",
          createdOn: "",
          department: { name: "" },
          emailAddress: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          profilePublicId: "",
          publicId: "",
          status: "",
        },
      ],
      name: "",
      publicId: "",
      avatarUrl: variables.AVATAR_ROUTE,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      capitalize,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
      capitalize,
    };
  },
  created() {
    this.getAdministrators();
  },
  methods: {
    getAdministrators() {
      //Fetch list of all departments
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.SUPERADMIN_IBN_USERS_ROUTE}/admin`)
          .then(({ data }) => {
            //Assign data to form fields
            this.administrators = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteAdministrator(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.SUPERADMIN_USER_ROUTE}/${publicId}`)
          .then(({ data }) => {
            variables.toastAlert(data.message, "success");
            this.getAdministrators();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    toggleStatus(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(
          `${variables.SUPERADMIN_USER_STATUS_ROUTE}/${publicId}`,
          []
        )
          .then(({ data }) => {
            variables.toastAlert(data.message, "success");
            this.getAdministrators();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
  },
});
