
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AdministratorsTable from "@/components/admin/AdministratorsTable.vue";

export default defineComponent({
  name: "admin-administrators-list",
  components: { AdministratorsTable },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("List", ["Users", "Administrators"]);
    });
  },
  created() {
    //Set page title
    document.title = "Administrators List | " + process.env.VUE_APP_TITLE;
  },
});
